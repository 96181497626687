$PUBLIC_S3_ASSETS_URL: "https://assets.go.mindsets.com";
.what-is-new {
  margin-left: 25px;

  .mindsets-dropdown-trigger {
    i {
      color: #fff;
    }
  }

  .mindsets-dropdown-content {
    .mindsets-dropdown-content-item {
      font-size: 14px !important;
      color: initial !important;
      padding: 5px 10px;
    }
  }
}
