@import "colors";

input[type="text"], select {
  border: 1px solid $grey-border;
  color: $grey-text2;
  border-radius: 0.25rem;
  height: 32px;
  font-size: 14px;

  &.big {
    height: 52px;
    font-size: 18px;
  }

  &.little {
    height: 32px;
    font-size: 14px;
  }
}

.form-check {
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 1rem;

  .checkbox-clicker {
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

label {
  color: $grey-text;
  font-size: 14px;
}

.quick-search {
  margin-right: 15px;
}
