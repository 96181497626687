$PUBLIC_S3_ASSETS_URL: "https://assets.go.mindsets.com";
:root {
  --bs-body-font-size: 14px;
  --bs-pagination-font-size: 14px;
}

@import "./forms";
@import "./tables";
@import "./buttons";
@import "./colors";
@import "./avatars";

html {
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  a {
    text-decoration: unset;
  }
}

.container {
  &.full {
    max-width: initial;
  }
}

.clickable {
  cursor: pointer;
}

.per-pager {
  .current {
    text-decoration: underline;
  }
}

.section {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.table {
  margin-bottom: 0;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.no-wrap {
  white-space: nowrap;
}

.bordered {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;

  &.little {
    padding: 4px 8px;
    border-radius: 4px;
  }
}

.center {
  text-align: center;
}

#react-entry-point {
  padding-bottom: 41px;
  height: 100%;
}

.flex-gap-5 {
  gap: 5px;
}

.flex-gap-10 {
  gap: 10px;
}

.flex-gap-15 {
  gap: 15px;
}

.flex-gap-20 {
  gap: 20px;
}

.flex-gap-25 {
  gap: 25px;
}
