$PUBLIC_S3_ASSETS_URL: "https://assets.go.mindsets.com";
#error-page {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  display: table;
  font-weight: 100;
  font-size: 24px;

  .container {
    text-align: center;
    display: table-cell;
    vertical-align: middle;

    .content {
      text-align: center;
      display: inline-block;
      font-size: 20px;
    }
  }
}
