$PUBLIC_S3_ASSETS_URL: "https://assets.go.mindsets.com";
@import "./../../../css/colors";

table {
  .button-border {
    border-width: 3px !important;
  }

  .scoring-input {
    position: relative;

    .reasoning-trigger {
      color: #fff;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  tr.pseudo-disabled {
    background-color: $grey-background2;

    .scoring-input {
      .button-border.active {
        border-color: $grey-border2 !important;
      }

      .button-border.inactive {
        border-color: $grey-background2 !important;
      }

      button {
        color: $grey-text !important;
        cursor: initial !important;
      }
    }
  }
}
