$PUBLIC_S3_ASSETS_URL: "https://assets.go.mindsets.com";
.forbidden {
  .forbidden-title {
    font-weight: bold;
    font-size: 36px;
  }

  .forbidden-image {
    width: 84%;
    margin-top: 30px;
  }

  .forbidden-sub-title {
    font-weight: bold;
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .forbidden-back {
    font-size: 18px;

    a {
      font-weight: bold;
    }
  }

  .forbidden-contact-us {
    margin-top: 25px;
    font-size: 18px;

    a {
      font-weight: bold;
    }
  }
}
