@import "colors";

button, .button {
  height: 32px;
  border: 1px solid $blue;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: $blue;
  background-color: transparent;
  padding: 5px 10px;
  white-space: nowrap;
  display: inline-block;

  i:not(:last-child) {
    font-size: 13px;
    margin-right: 8px;
  }

  &:hover:not(:disabled) {
    box-shadow: 0 0 3px 0 $blue;
  }

  &:focus {
    outline: none
  }

  &:disabled {
    opacity: .4;
  }

  &:hover {
    text-decoration: none;
    color: $blue;
  }

  &.default {
    background-color: transparent;
    border: 1px solid transparent;
    color: $grey-text;

    &:hover:not(:disabled) {
      box-shadow: 0 0 3px 0 $grey-text;
    }
  }

  &.red {
    color: $red;
    border-color: $red;

    &:hover:not(:disabled) {
      box-shadow: 0 0 3px 0 $red;
    }
  }

  &.blue {
    color: white;
    border-color: white;
    background-color: $blue;
    box-shadow: rgba(0, 0, 0, 0.14) 0 2px 2px 0, rgba(0, 0, 0, 0.12) 0 1px 5px 0, rgba(0, 0, 0, 0.2) 0 3px 1px -2px;

    &:hover:not(:disabled) {
      color: white;
      border-color: white;
      background-color: $dark-blue;
    }
  }

  &.white {
    color: $blue;
    border-color: white;
    background-color: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: #F0F0F1;
    }
  }

  &.green {
    color: $green;
    border-color: $green;

    &:hover:not(:disabled) {
      box-shadow: 0 0 3px 0 $green;
    }
  }

  &.inactive {
    color: $grey-text2;
    border-color: $grey-text2;

    &:hover:not(:disabled) {
      box-shadow: 0 0 3px 0 $grey-text2;
    }
  }

  &.big {
    height: 42px;
    font-size: 16px;
  }

  &.large {
    height: 52px;
    font-size: 18px;
    line-height: 40px;
    border-width: 2px;
    padding: 0 1.5rem;

    i {
      font-size: 20px;

      &:first-child:not(:last-child) {
        margin-right: 10px;
      }

      &:last-child:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

.button-border {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 1px;

  &.active {
    border: 1px solid $light-blue;
  }

  &.red {
    border-color: $red;
  }
}
