@import "./colors";

.table.sticky > thead:first-child > tr:first-child > th:first-child, .table.sticky > tbody > tr > td:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 1;
}

.table > :not(caption) > * > * {
  background-color: inherit;
  border-width: 1px;
  border-color: $grey-border2 !important;
}
