.mindsets-icon {
  background-image: url($PUBLIC_S3_ASSETS_URL + '/application/avatars.png');
}

.mindsets-icon-mindsets-25 {
  background-position: -400px -160px;
  width: 33px;
  height: 33px;
}
.mindsets-icon-mindsets {
  background-position: -280px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-blank-black {
  background-position: -0px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-blank-white {
  background-position: -40px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-default {
  background-position: -80px -0px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-01 {
  background-position: -80px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-02 {
  background-position: -0px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-03 {
  background-position: -40px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-04 {
  background-position: -80px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-05 {
  background-position: -120px -0px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-06 {
  background-position: -120px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-07 {
  background-position: -120px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-08 {
  background-position: -0px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-09 {
  background-position: -40px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-10 {
  background-position: -80px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-11 {
  background-position: -120px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-12 {
  background-position: -160px -0px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-13 {
  background-position: -160px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-14 {
  background-position: -160px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-15 {
  background-position: -160px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-16 {
  background-position: -0px -160px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-17 {
  background-position: -40px -160px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-18 {
  background-position: -80px -160px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-19 {
  background-position: -120px -160px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-20 {
  background-position: -160px -160px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-21 {
  background-position: -200px -0px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-22 {
  background-position: -200px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-23 {
  background-position: -200px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-24 {
  background-position: -200px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-25 {
  background-position: -200px -160px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-26 {
  background-position: -0px -200px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-27 {
  background-position: -40px -200px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-28 {
  background-position: -80px -200px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-29 {
  background-position: -120px -200px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-30 {
  background-position: -160px -200px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-31 {
  background-position: -200px -200px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-32 {
  background-position: -240px -0px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-33 {
  background-position: -240px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-34 {
  background-position: -240px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-35 {
  background-position: -240px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-36 {
  background-position: -240px -160px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-37 {
  background-position: -240px -200px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-38 {
  background-position: -0px -240px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-39 {
  background-position: -40px -240px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-40 {
  background-position: -80px -240px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-41 {
  background-position: -120px -240px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-42 {
  background-position: -160px -240px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-43 {
  background-position: -200px -240px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-44 {
  background-position: -240px -240px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-45 {
  background-position: -280px -0px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-46 {
  background-position: -280px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-47 {
  background-position: -280px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-48 {
  background-position: -0px -0px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-49 {
  background-position: -280px -160px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-girl-50 {
  background-position: -280px -200px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-01 {
  background-position: -280px -240px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-02 {
  background-position: -0px -280px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-03 {
  background-position: -40px -280px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-04 {
  background-position: -80px -280px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-05 {
  background-position: -120px -280px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-06 {
  background-position: -160px -280px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-07 {
  background-position: -200px -280px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-08 {
  background-position: -240px -280px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-09 {
  background-position: -280px -280px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-10 {
  background-position: -320px -0px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-11 {
  background-position: -320px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-12 {
  background-position: -320px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-13 {
  background-position: -320px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-14 {
  background-position: -320px -160px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-15 {
  background-position: -320px -200px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-16 {
  background-position: -320px -240px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-17 {
  background-position: -320px -280px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-18 {
  background-position: -0px -320px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-19 {
  background-position: -40px -320px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-20 {
  background-position: -80px -320px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-21 {
  background-position: -120px -320px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-22 {
  background-position: -160px -320px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-23 {
  background-position: -200px -320px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-24 {
  background-position: -240px -320px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-25 {
  background-position: -280px -320px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-26 {
  background-position: -320px -320px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-27 {
  background-position: -360px -0px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-28 {
  background-position: -360px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-29 {
  background-position: -360px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-30 {
  background-position: -360px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-31 {
  background-position: -360px -160px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-32 {
  background-position: -360px -200px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-33 {
  background-position: -360px -240px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-34 {
  background-position: -360px -280px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-35 {
  background-position: -360px -320px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-36 {
  background-position: -0px -360px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-37 {
  background-position: -40px -360px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-38 {
  background-position: -80px -360px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-39 {
  background-position: -120px -360px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-40 {
  background-position: -160px -360px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-41 {
  background-position: -200px -360px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-42 {
  background-position: -240px -360px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-43 {
  background-position: -280px -360px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-44 {
  background-position: -320px -360px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-45 {
  background-position: -360px -360px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-46 {
  background-position: -400px -0px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-47 {
  background-position: -400px -40px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-48 {
  background-position: -400px -80px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-49 {
  background-position: -400px -120px;
  width: 40px;
  height: 40px;
}
.cashtivity-icon-avatar-guy-50 {
  background-position: -40px -0px;
  width: 40px;
  height: 40px;
}
