$grey-lighten-1: #979797;
$grey: #ccc;
$grey-border: #ced4da;
$grey-border2: #8a8d94;
$grey-text: #939393;
$grey-text2: #707070;
$grey-text3: #555555;
$grey-text4: #373A3C;
$grey-text5: rgba(55, 58, 60, 0.5);
$grey-background: #F9F9F9;
$grey-background2: #e5e5e5;
$background5: #EEE;

$green: #13BF8C;

$blue: #22A1D7;
$blue-background: #24A2D7;
$blue-background-light: rgba(159, 210, 245, 0.3);
$light-blue: #32b7ff;
$dark-blue: #298ab4;

$mindsets-teacher: #494fa2;

$red: #bd2130;

.green {
  color: $green;
}

.bg-grey {
  background-color: $grey-background2 !important;
  color: black;
}

.bg-blue {
  background-color: $blue-background-light !important;
  color: black;
}

.bg-warning {
  color: black;
}
